import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Amplify from 'aws-amplify';
import awsExports from './aws-exports';
import { HubService } from './core/hub.service';

import { PostHogProvider } from 'posthog-js/react';

Amplify.configure(awsExports);
HubService.initalize();

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_API_HOST,
        ui_host: process.env.REACT_APP_PUBLIC_POSTHOG_UI_HOST,
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
          },
        },
      }}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
