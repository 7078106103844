import {
  AccessRole,
  ConferenceDay,
  ConferenceElement,
  ConferenceGrouping,
  ConferenceItem,
  MasterSwitchItem,
  PermissionName,
} from './enums';
import { PasswordPolicy } from './interfaces';

export const PASSWORD_POLICIES: PasswordPolicy[] = [
  { label: '8+ characters', regex: /^\S{8,99}$/ },
  { label: 'number', regex: /\d/ },
  { label: 'uppercase letter', regex: /[A-Z]/ },
  { label: 'lowercase letter', regex: /[a-z]/ },
  { label: 'special character', regex: /[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/ },
];

export const ACCESS_ROLE: Record<AccessRole, string> = {
  [AccessRole.Admin]: 'Admin',
  [AccessRole.GroupLeader]: 'Group Leader',
  [AccessRole.Registrar]: 'Registrar',
};

export const PERMISSION_NAME: Record<PermissionName, string> = {
  [PermissionName.EditPrivateReceptionsAndWorkshop]: 'Edit Private Receptions & Workshop',
  [PermissionName.ManageGroupLeaders]: 'Manage Group Leaders',
  [PermissionName.ViewActivityLog]: 'View Activity Log',
};

export const EMOJI: Record<ConferenceItem, string> = {
  [ConferenceItem.Speakers]: '🗣️',
  [ConferenceItem.Breakfast]: '🍳',
  [ConferenceItem.Lunch]: '🥪',
  [ConferenceItem.Dinner]: '🍽️',
  [ConferenceItem.Reception]: '🥂',
  [ConferenceItem.Workshop]: '🛠️',
};

export const EMOJI2: Record<ConferenceElement, string> = {
  [ConferenceItem.Speakers]: '🗣️',
  [ConferenceItem.Breakfast]: '🍳',
  [ConferenceItem.Lunch]: '🥪',
  [ConferenceItem.Dinner]: '🍽️',
  [ConferenceItem.Workshop]: '🛠️',
  [ConferenceDay.Thursday]: '🍸',
  [ConferenceDay.Friday]: '🍺',
};

export const REGISTRATION_OBJECT: Record<ConferenceDay, ConferenceItem[]> = {
  [ConferenceDay.Thursday]: [ConferenceItem.Reception],
  [ConferenceDay.Friday]: [
    ConferenceItem.Speakers,
    ConferenceItem.Breakfast,
    ConferenceItem.Lunch,
    ConferenceItem.Reception,
    ConferenceItem.Dinner,
  ],
  [ConferenceDay.Saturday]: [
    ConferenceItem.Speakers,
    ConferenceItem.Breakfast,
    ConferenceItem.Lunch,
    ConferenceItem.Dinner,
  ],
};

export const REGISTRATION_GROUPING: Record<ConferenceGrouping, ConferenceElement[]> = {
  [ConferenceItem.Reception]: [ConferenceDay.Thursday, ConferenceDay.Friday],
  [ConferenceDay.Friday]: [
    ConferenceItem.Speakers,
    ConferenceItem.Breakfast,
    ConferenceItem.Lunch,
    ConferenceItem.Dinner,
    ConferenceItem.Workshop,
  ],
  [ConferenceDay.Saturday]: [
    ConferenceItem.Speakers,
    ConferenceItem.Breakfast,
    ConferenceItem.Lunch,
    ConferenceItem.Dinner,
  ],
};

export const REGISTRATION_ARRAY = Object.entries(REGISTRATION_GROUPING) as [ConferenceGrouping, ConferenceElement[]][];
export const REGISTRATION_OBJECT_KEYS = Object.keys(REGISTRATION_GROUPING) as ConferenceGrouping[];

export const PRICING: Record<ConferenceItem, number> = {
  [ConferenceItem.Reception]: 0,
  [ConferenceItem.Workshop]: 0,
  [ConferenceItem.Speakers]: 35,
  [ConferenceItem.Breakfast]: 25,
  [ConferenceItem.Lunch]: 30,
  [ConferenceItem.Dinner]: 60,
};

export const MASTER_SWITCHES: MasterSwitchItem[] = [
  MasterSwitchItem.FridayLunchSoldOut,
  MasterSwitchItem.FridayDinnerSoldOut,
  MasterSwitchItem.SaturdayLunchSoldOut,
  MasterSwitchItem.SaturdayDinnerSoldOut,
];
